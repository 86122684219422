import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import {
  convertCurrency,
  getCustomer,
  getExchangeRate,
  onboardCustomer,
} from "./thunk";
import { CurrencySwap, User } from "../types";
import { allCountries } from "../../subscriptionservice/store/thunk";

export type appState = {
  user: User | null;
  missingFields: string[];
  isloading: boolean;
  info: {
    balance: string;
    url: string;
  };
  currency_info: {
    loading: boolean;
    data: CurrencySwap | null;
    is_currency_same: boolean;
  };
  countries_assets: {
    loading: boolean;
    data: any[];
  };
  exchange_rate: {
    converted_value: number;
    from: string;
    message: string;
    to: string;
    unit: number;
  } | null;
};

const initialState: appState = {
  user: null,
  missingFields: [],
  isloading: false,
  info: {
    balance: "",
    url: "",
  },
  currency_info: {
    loading: false,
    data: null,
    is_currency_same: false,
  },
  countries_assets: {
    data: [],
    loading: false,
  },
  exchange_rate: null,
};

const AppState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    closeModal: (state) => {
      window.parent.postMessage({ name: "Close" }, "*");
      document.getElementById("$_fswich_iframe")?.remove();
    },
    addInfo: (state, { payload }: PayloadAction<Record<string, string>>) => {
      state.info.balance = payload.balance;
      state.info.url = payload.url;
    },
    resetMissingField: (state) => {
      state.missingFields = initialState.missingFields;
    },
    resetCurrencyInfo: (state) => {
      state.currency_info = { ...initialState.currency_info };
    },
    resetExchangeRate: (state) => {
      state.exchange_rate = initialState.exchange_rate;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomer.fulfilled, (state, { payload }) => {
        if (payload.user_exist === false) {
          state.missingFields = payload.missing_fields;
        }
      })
      .addCase(convertCurrency.fulfilled, (state, { payload }) => {
        state.currency_info.data = payload;

        state.currency_info.is_currency_same =
          state?.user?.asset?.country_currency.toLowerCase() ===
          payload.source_currency;
      })
      .addCase(convertCurrency.pending, (state) => {
        state.currency_info.loading = true;
      })
      .addCase(allCountries.fulfilled, (state, { payload }) => {
        state.countries_assets.data = payload?.data;
      })
      .addCase(allCountries.pending, (state) => {
        state.countries_assets.loading = true;
      })
      .addCase(getExchangeRate.fulfilled, (state, { payload }) => {
        state.exchange_rate = payload;
      })
      .addMatcher(
        isAnyOf(convertCurrency.fulfilled, convertCurrency.rejected),
        (state) => {
          state.currency_info.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(allCountries.fulfilled, allCountries.rejected),
        (state) => {
          state.countries_assets.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(onboardCustomer.fulfilled, getCustomer.fulfilled),
        (state, { payload }) => {
          if (payload.user_exist === true) {
            state.user = payload;
          }
        }
      )
      .addMatcher(
        isAnyOf(onboardCustomer.pending, getCustomer.pending),
        (state) => {
          state.isloading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          onboardCustomer.fulfilled,
          getCustomer.fulfilled,
          onboardCustomer.rejected,
          getCustomer.rejected
        ),
        (state) => {
          state.isloading = false;
        }
      );
  },
});

export const { closeModal, addInfo, resetCurrencyInfo, resetExchangeRate } =
  AppState.actions;

export default AppState.reducer;
