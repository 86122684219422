import { RegisterUser, User } from "../../module/dashboard/types";
import store from "../../store";
import { closeModal } from "../../module/auth/store/slice";
import { QueryListParams } from "../types";

export const validationRules = {
  string: /(.|\s)*\S(.|\s)*/,
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
  number: /^\d+$/,
  website:
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, //eslint-disable-line
};

export const addCommas = (num: string) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = (num: string) =>
  num?.toString()?.replace(/[^0-9]/g, "");
export const removeCommas = (num: string) =>
  num?.toString()?.replace(/[, ]+/g, "").trim();

export const parseQueryParams = (query: QueryListParams): string => {
  const { app_id, country, end_date, start_date, name } = query;
  const params = `${app_id ? `app_id=${app_id}&` : ""}${
    country ? `country=${country}&` : ""
  }${end_date ? `end_date=${end_date}&` : ""}${
    start_date ? `start_date=${start_date}&` : ""
  }${name ? `name=${name}` : ""}`;
  return params;
};

export const formatFloatCurrency = (amount: string) => {
  const amt = (Math.floor(parseFloat(amount) * 100) / 100).toString();
  const [int, dec] = amt.split(".");

  const nums = addCommas(int);

  const res = dec === undefined ? nums : `${nums}.${dec}`;

  return res;
};

export const convertNumberToCurrency = (
  locale: "en-US" | "en-NG",
  currency: "USD" | "NGN",
  number: number
) => {
  const CURRENCY_CONVERTER = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
  });

  return CURRENCY_CONVERTER.format(number);
};

export const removeUnderScoreAddSpace = (str: string): string =>
  str.split("_").join(" ");

export const lastPathRoute = (str: string): string => {
  const split_string = str.split("/");
  return split_string[split_string.length - 1];
};

export const convertBankFieldsAndRules = (arr: any[]) => {
  const res: any[] = [];

  arr.forEach((itm) => {
    const temp: any = {};
    temp.title = removeUnderScoreAddSpace(itm.name).toLowerCase();
    // temp.rules = `^.{${itm.min_length},${itm.max_length}}$`;
    temp.rules =
      itm.name.includes("code") || itm.name.includes("number")
        ? validationRules.number
        : validationRules.string;
    temp.min_length = itm.min_length;
    temp.max_length = itm.max_length;
    temp.name = itm.name;
    temp.value_type = itm.value_type;
    temp._id = itm._id;
    temp.is_required = itm.is_required;
    res.push(temp);
  });

  return res;
};

export const convertUserData = (parsed_data: User) => {
  let temp: RegisterUser = {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    user_reference: "",
    reference: "",
  };

  (Object.keys(parsed_data) as Array<keyof typeof parsed_data>).forEach(
    (key) => {
      if (key !== "public_key") {
        temp[key] = parsed_data[key];
      }
      if (
        (key === "user_reference" || key === "public_key") &&
        !parsed_data[key]
      ) {
        store.dispatch(closeModal());
      }
    }
  );

  return temp;
};
