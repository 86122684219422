import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { Module, SocketQuery } from "../types";
import {
  initiateTransfer,
  resolveCustomerAccountWithPhoneNumber,
  uploadPaymentEvidence,
} from "./thunk";

const initialState: Module = {
  isloading: false,
  resolved_user: {
    data: null,
    loading: false,
    error: false,
  },
  socket: {
    isEstablishingConnection: false,
    isConnected: false,
    response: null,
    query: {
      amount: null,
      txn_reference: null,
    },
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    startConnecting: (state, { payload }: PayloadAction<SocketQuery>) => {
      state.socket.isEstablishingConnection = true;
      state.socket.query.amount = payload.amount;
      state.socket.query.txn_reference = payload.txn_reference;
    },
    connectionEstablished: (state) => {
      state.socket.isConnected = true;
      state.socket.isEstablishingConnection = false;
    },
    receiveMessage: (
      state,
      action: PayloadAction<{
        data: any;
      }>
    ) => {
      state.socket.response = action.payload.data;
    },
    submitMessage: (
      state,
      action: PayloadAction<{
        content: any;
      }>
    ) => {
      return;
    },
    startDisconecting: (state) => {
      state.socket.isEstablishingConnection = false;
    },
    disconnectionEstablished: (state) => {
      state.socket.isConnected = false;
      state.socket.isEstablishingConnection = false;
      state.socket.response = null;
      state.socket.query.amount = null;
      state.socket.query.txn_reference = null;
    },
    resetResolvedUser: (state) => {
      state.resolved_user = { ...initialState.resolved_user };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        resolveCustomerAccountWithPhoneNumber.fulfilled,
        (state, { payload }) => {
          state.resolved_user.data = payload;
        }
      )
      .addCase(resolveCustomerAccountWithPhoneNumber.rejected, (state) => {
        state.resolved_user.error = true;
      })
      .addMatcher(
        isAnyOf(uploadPaymentEvidence.pending, initiateTransfer.pending),
        (state) => {
          state.isloading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          uploadPaymentEvidence.fulfilled,
          uploadPaymentEvidence.rejected,
          initiateTransfer.fulfilled,
          initiateTransfer.rejected
        ),
        (state) => {
          state.isloading = false;
        }
      )
      .addMatcher(
        isAnyOf(resolveCustomerAccountWithPhoneNumber.pending),
        (state) => {
          state.resolved_user.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          resolveCustomerAccountWithPhoneNumber.fulfilled,
          resolveCustomerAccountWithPhoneNumber.pending
        ),
        (state) => {
          state.resolved_user.error = false;
        }
      )
      .addMatcher(
        isAnyOf(
          resolveCustomerAccountWithPhoneNumber.fulfilled,
          resolveCustomerAccountWithPhoneNumber.rejected
        ),
        (state) => {
          state.resolved_user.loading = false;
        }
      );
  },
});

export const walletActions = walletSlice.actions;
export default walletSlice.reducer;
