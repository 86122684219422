export const routes = {
  onboard: "/onboard",
  dashboard: "/",
  bank_transfer: {
    index: "/bank-transfer",
    select_bank: "/bank-transfer/select-bank",
    verification_code: "/bank-transfer/verification-code",
    success: "/bank-transfer/success",
  },
  // airtime: "/airtime",
  // data: "/data",
  tv: "/tv-cable",
  select_platform: "/select-platform",
  wallet_transfer_authorize: "/wallet-transfer/authorize",
  wallet_transfer: "/wallet-transfer",
  // wallet_transfer_vr_code: "/wallet-transfer/verify",
  gift_card: "/gift-card",
  utility: {
    all: "/utility",
    authorize: "/utility/authorize",
    success: "/utility/success",
    dynamic_type: {
      index: "/utility/:type",
      link: (type: string) => `/utility/${type}`,
    },
  },
  fund_wallet: {
    initialform: "/fund-wallet",
    payment_evidence: "/fund-wallet/payment-evidence",
    success: "/fund-wallet/success",
    verification_code: "/fund-wallet/verification-code",
  },
  subscription_services: {
    dashboard: "/subscription-service",
    all: "/subscription-services",
    subscription_services_item: {
      index: "/subscription-service/:userserviceid",
      link: (id: string) => `/subscription-service/${id}`,
      all: {
        index: "/subscription-services/:allserviceid",
        link: (id: string) => `/subscription-services/${id}`,
      },
      enter_otp: {
        index: "/subscription-services/:serviceid/otp",
      },
      verification: "/subscription-services/verify",
      success: "/subscription-services/success",
    },
  },
  checkout: {
    index: "/checkout",
    verification: "/checkout/verify",
    success: "/checkout/success",
  },
};
