import { useEffect } from "react";

export function useCusorLoader(isLoading: boolean) {
  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = "progress";
    } else {
      document.body.style.cursor = "default";
    }

    return () => {
      document.body.style.cursor = "default";
    };
  }, [isLoading]);

  return;
}
