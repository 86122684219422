import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IsubscriptionService } from "../types";
import {
  createSubscriptionService,
  listAllSubscriptionService,
  listSubscriptionService,
  updateSubscriptionService,
} from "./thunk";

const initialState: IsubscriptionService = {
  user_list: {
    loading: false,
    data: [],
  },
  all_app_list: {
    loading: false,
    data: [],
  },
};

const subscriptionService = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    resetUserAppsList: (state) => {
      state.user_list.data = initialState.user_list.data;
    },
    resetAllAppList: (state) => {
      state.all_app_list.data = initialState.all_app_list.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listSubscriptionService.fulfilled, (state, { payload }) => {
        state.user_list.data = payload;
      })
      .addCase(createSubscriptionService.fulfilled, (state, { payload }) => {
        state.user_list.data.push(payload);
      })
      .addCase(updateSubscriptionService.fulfilled, (state, { payload }) => {
        const temp_list = state.user_list.data.map((list_itm) =>
          list_itm._id === payload._id ? payload : list_itm
        );
        state.user_list.data = [...temp_list];
      })
      .addCase(listAllSubscriptionService.fulfilled, (state, { payload }) => {
        state.all_app_list.data = payload;
      })
      .addMatcher(
        isAnyOf(
          listSubscriptionService.pending,
          createSubscriptionService.pending,
          updateSubscriptionService.pending
        ),
        (state) => {
          state.user_list.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          listSubscriptionService.fulfilled,
          listSubscriptionService.rejected,
          createSubscriptionService.fulfilled,
          createSubscriptionService.rejected,
          updateSubscriptionService.fulfilled,
          updateSubscriptionService.rejected
        ),
        (state) => {
          state.user_list.loading = false;
        }
      )
      .addMatcher(isAnyOf(listAllSubscriptionService.pending), (state) => {
        state.all_app_list.loading = true;
      })
      .addMatcher(
        isAnyOf(
          listAllSubscriptionService.fulfilled,
          listAllSubscriptionService.rejected
        ),
        (state) => {
          state.all_app_list.loading = true;
        }
      );
  },
});

export const subscriptionServiceActions = subscriptionService.actions;

export default subscriptionService.reducer;
