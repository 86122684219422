import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  message: "",
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    addMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    clearMessage: (state) => {
      state.message = "";
    },
  },
});

export const errorActions = errorSlice.actions;
export default errorSlice.reducer;
