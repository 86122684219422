export const BASE_URL = process.env.REACT_APP_FINSWICH_CHECKOUT;

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export const STORAGE_KEYS = {
  CLIENT_USER_REFRENCE: "checkout.userreference",
  CLIENT_PUBLIC_KEY: "checkout.publickey",
  CLIENT_ORIGIN: "checkout.origin",
};
