import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import * as tp from "../types";

export const listAccountTypes = createAsyncThunk(
  "Bank-transfer/listAccountTypes",
  async (id: string) => {
    return service
      .listAccountTypes(id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const initiateBankTransfer = createAsyncThunk(
  "Bank-transfer/initiateBankTransfer",
  async (
    req: tp.InitiateBankTransferThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data } = req;
    return service
      .initiateBankTransfer(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const resendOtp = createAsyncThunk(
  "Bank-transfer/resendOtp",
  async (data: tp.SendOtp) => {
    return service
      .resendOtp(data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const completeBankTransfer = createAsyncThunk(
  "Bank-transfer/completeBankTransfer",
  async (
    data: tp.CompleteBankTransfer,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service
      .completeBankTransfer(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
