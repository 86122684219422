import { parseQueryParams } from "../../helpers";
import { QueryListParams } from "../../types";

export const ENDPOINTS = {
  user: {
    onboard: {
      method: "POST",
      url: "/external/consumer/onboard",
    },
    retrieve: {
      method: "GET",
      url: (ref: string) => `/external/consumer/user/${ref}`,
    },
  },
  countries_list: {
    method: "GET",
    url: "/auth/assets",
  },
  subscription_service: {
    list: {
      method: "GET",
      url: "/subscription-service",
    },
    create: {
      method: "POST",
      url: "/subscription-service",
    },
    update: {
      method: "PATCH",
      url: (id: string) => `/subscription-service/${id}`,
    },
    retrive_single: {
      method: "GET",
      url: (id: string) => `/subscription-service/${id}`,
    },
    retrive_single_app: {
      method: "GET",
      url: (id: string) => `/subscription-service/apps/${id}`,
    },
    list_all: {
      method: "GET",
      url: (query: QueryListParams) => {
        const params = parseQueryParams(query);
        return `/subscription-service/apps?${params}`;
      },
    },
  },
  wallet_transfer: {
    list_platforms: {
      method: "GET",
      url: (query: QueryListParams) => {
        const params = parseQueryParams(query);
        return `/inter-wallet-transfer/platforms?${params}`;
      },
    },
    initiate_transfer: {
      method: "POST",
      url: "/inter-wallet-transfer/initiate",
    },
    resend_otp: {
      method: "POST",
      url: "/inter-wallet-transfer/otp/send",
    },
    complete_transfer: {
      method: "POST",
      url: "/inter-wallet-transfer/complete",
    },
    reslove_customer_account_with_phone_number: {
      method: "POST",
      url: "/inter-wallet-transfer/user/resolve",
    },
  },
  bank_transfer: {
    list_account_type: {
      method: "GET",
      url: (accountId: string) => {
        return `/transfer/bank/${accountId}/account/types`;
      },
    },
    initiate_transfer: {
      method: "POST",
      url: "/transfer/bank/initiate",
    },
    resend_otp: {
      method: "POST",
      url: "/transfer/bank/otp/send",
    },
    complete_transfer: {
      method: "POST",
      url: "/transfer/bank/complete",
    },
  },
  fund_wallet: {
    upload_evidence: {
      method: "POST",
      url: `/external/fund/evidence`,
    },
  },
  checkout: {
    get_checkout_services: {
      method: "GET",
      url: `/checkout/subscribed-services`,
    },
    initiate_checkout: {
      method: "POST",
      url: "/checkout/initiate",
    },
    complete_transaction: {
      method: "POST",
      url: "/checkout/complete",
    },
    resend_otp: {
      method: "POST",
      url: "/checkout/otp/send",
    },
  },
  payment_util: {
    method: "POST",
    url: "/payment/util/currency-swap",
  },
  utility: {
    fetch_categories: {
      method: "GET",
      url: "/utilities/categories",
    },
    fetch_single_categories: {
      method: "GET",
      url: (cid: string) => `/utilities/categories/${cid}`,
    },
    get_operators: {
      method: "POST",
      url: (cid: string) => `/utilities/categories/${cid}/operators`,
    },
    get_operator_products: {
      method: "GET",
      url: (cid: string, oid: string) =>
        `/utilities/categories/${cid}/operators/${oid}/products`,
    },
    get_single_operator_product: {
      method: "GET",
      url: (cid: string, oid: string, pid: string) =>
        `/utilities/categories/${cid}/operators/${oid}/products/${pid}`,
    },
    validate_phonenumber: {
      method: "POST",
      url: "/utilities/validate/phone-number",
    },
    initiate_transaction: {
      method: "POST",
      url: "/utilities/transaction/initiate",
    },
    resend_otp: {
      method: "POST",
      url: "/utilities/transaction/otp/send",
    },
    complete_transaction: {
      method: "POST",
      url: "/utilities/transaction/complete",
    },
  },
};
