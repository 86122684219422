import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import { APIResponseModel, QueryListParams } from "../../../common/types";
import {
  IcreateService,
  IsubscriptionServiceItem,
  IupdateService,
} from "../types";

const service = {
  async allCountries(): Promise<APIResponseModel<any[]>> {
    const { method, url } = ENDPOINTS.countries_list;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listUserSubscriptionService(): Promise<
    APIResponseModel<IsubscriptionServiceItem[]>
  > {
    const { method, url } = ENDPOINTS.subscription_service.list;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async createUserSubscriptionService(
    data: IcreateService
  ): Promise<APIResponseModel<IsubscriptionServiceItem>> {
    const { method, url } = ENDPOINTS.subscription_service.create;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async updateUserSubscriptionService({ subid, data }: IupdateService) {
    const { method, url: makeUrl } = ENDPOINTS.subscription_service.update;
    const url = makeUrl(subid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async singleSubscriptionService(uid: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.subscription_service.retrive_single;
    const url = makeUrl(uid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async listAllSubscriptionService(query: QueryListParams) {
    const { method, url: makeUrl } = ENDPOINTS.subscription_service.list_all;
    const url = makeUrl(query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async singleAppSubscriptionService(uid: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.subscription_service.retrive_single_app;
    const url = makeUrl(uid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
