import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as tp from "../types";
import * as tk from "./thunk";

const initialState: tp.Module = {
  country: "",
  categories: {
    loading: false,
    data: [],
  },
  operators: {
    loading: false,
    data: {},
  },
  selected_category: {
    id: "",
    is_mobile_top_up: false,
    is_msid: false,
  },
  selected_operator_products: null,
  selected_operator_single_product: null,
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: {
    resetCategories: (state) => {
      state.categories.data = initialState.categories.data;
      state.selected_category = initialState.selected_category;
    },
    selectCategory: (state, { payload }: PayloadAction<string>) => {
      state.selected_category.id = payload;
      state.selected_category.is_mobile_top_up = payload === "1.0";
      state.selected_category.is_msid = ["1.0", "2.0", "4.0", "5.0"].includes(
        payload
      );

      // payload === "645cf2e0a97fd6ba20cbc297";
    },
    addCountryName: (state, { payload }: PayloadAction<string>) => {
      state.country = payload;
    },
    resetCountryName: (state) => {
      state.country = initialState.country;
    },
    resetProduct: (state) => {
      state.selected_operator_products =
        initialState.selected_operator_products;
    },
    resetProducts: (state) => {
      state.selected_operator_products =
        initialState.selected_operator_products;
      state.selected_category = initialState.selected_category;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(tk.fetchCategories.fulfilled, (state, { payload }) => {
        state.categories.data = payload;
      })
      // .addCase(tk.fetchSingleCategory.fulfilled, (state, { payload }) => {
      //   state.selected_category = payload;
      // })
      .addCase(tk.fetchOperators.fulfilled, (state, { payload }) => {
        state.operators.data = payload;
      })
      .addCase(tk.fetchOperatorProducts.fulfilled, (state, { payload }) => {
        state.selected_operator_products = payload;
      })
      .addCase(
        tk.fetchSingleOperatorProduct.fulfilled,
        (state, { payload }) => {
          state.selected_operator_single_product = payload;
        }
      );
  },
});

export const utilityActions = utilitySlice.actions;
export default utilitySlice.reducer;
