import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import * as tp from "../types";

const service = {
  async fetchCategories(): Promise<tp.APIResponseModel<tp.Category[]>> {
    const { method, url } = ENDPOINTS.utility.fetch_categories;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async fetchSingleCategory(
    cid: string
  ): Promise<tp.APIResponseModel<tp.Category>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.fetch_single_categories;
    const url = makeUrl(cid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async fetchOperators(
    cid: string,
    data: tp.thunkGetOperator
  ): Promise<tp.APIResponseModel<Record<string, tp.Operator>>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.get_operators;
    const url = makeUrl(cid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async fetchOperatorProducts(
    cid: string,
    oid: string
  ): Promise<tp.APIResponseModel<tp.OperatorProducts>> {
    const { method, url: makeUrl } = ENDPOINTS.utility.get_operator_products;
    const url = makeUrl(cid, oid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async fetchSingleOperatorProduct(
    cid: string,
    oid: string,
    pid: string
  ): Promise<tp.APIResponseModel<tp.OperatorProductItem>> {
    const { method, url: makeUrl } =
      ENDPOINTS.utility.get_single_operator_product;
    const url = makeUrl(cid, oid, pid);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async validatePhoneNumber(
    data: tp.thunkValidatePhone
  ): Promise<tp.APIResponseModel<tp.PhoneNumberData>> {
    const { method, url } = ENDPOINTS.utility.validate_phonenumber;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async initiateTransaction(
    data: tp.thunkInitiateTransaction
  ): Promise<tp.APIResponseModel<string>> {
    const { method, url } = ENDPOINTS.utility.initiate_transaction;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resendOtp(data: tp.thunkResendOtp): Promise<tp.APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.utility.resend_otp;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async completeTransaction(
    data: tp.thunkCompleteTransaction
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.utility.complete_transaction;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
