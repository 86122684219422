import { lazy } from "react";
import { routes } from ".";
import { Iroute } from "../types";

const loadModules = (module: string, element: string) =>
  lazy(() => import(`../../module/${module}/pages/${element}`));

export const route: Iroute[] = [
  {
    path: routes.dashboard,
    Component: loadModules("dashboard", "Dashboard"),
  },
  {
    path: routes.onboard,
    Component: loadModules("dashboard", "Onboard"),
  },
  {
    path: routes.bank_transfer.index,
    Component: loadModules("banktransfer", "BankTransfer"),
  },
  {
    path: routes.bank_transfer.select_bank,
    Component: loadModules("banktransfer", "SelectBank"),
  },
  {
    path: routes.bank_transfer.verification_code,
    Component: loadModules("banktransfer", "VerificationCode"),
  },
  {
    path: routes.bank_transfer.success,
    Component: loadModules("banktransfer", "Success"),
  },
  {
    path: routes.utility.all,
    Component: loadModules("utilities", "AllUtilities"),
  },
  {
    path: routes.utility.dynamic_type.index,
    Component: loadModules("utilities", "UtlilityCategory"),
  },
  {
    path: routes.utility.authorize,
    Component: loadModules("utilities", "UtilityAuthorize"),
  },
  {
    path: routes.utility.success,
    Component: loadModules("utilities", "UtilitySuccess"),
  },
  // {
  //   path: routes.utility.airtime,
  //   Component: loadModules("utilities", "Airtime"),
  // },
  // {
  //   path: routes.utility.data,
  //   Component: loadModules("utilities", "Data"),
  // },
  {
    path: routes.wallet_transfer,
    Component: loadModules("wallet", "WalletTransfer"),
  },

  {
    path: routes.wallet_transfer_authorize,
    Component: loadModules("wallet", "WalletTransferAuthorize"),
  },
  {
    path: routes.fund_wallet.initialform,
    Component: loadModules("wallet", "FundWallet"),
  },
  {
    path: routes.fund_wallet.payment_evidence,
    Component: loadModules("wallet", "FundWalletPaymentEvidence"),
  },
  {
    path: routes.fund_wallet.verification_code,
    Component: loadModules("wallet", "FundWalletVerificationCode"),
  },
  {
    path: routes.fund_wallet.success,
    Component: loadModules("wallet", "FundWalletSuccess"),
  },
  {
    path: routes.select_platform,
    Component: loadModules("wallet", "SelectPlatform"),
  },
  {
    path: routes.subscription_services.dashboard,
    Component: loadModules("subscriptionservice", "SubscriptionService"),
  },
  {
    path: routes.subscription_services.all,
    Component: loadModules("subscriptionservice", "AllSubscriptionService"),
  },
  {
    path: routes.subscription_services.subscription_services_item.index,
    Component: loadModules("subscriptionservice", "SubscriptionServiceItem"),
  },
  {
    path: routes.subscription_services.subscription_services_item.all.index,
    Component: loadModules("subscriptionservice", "AllSubscriptionServiceItem"),
  },
  {
    path: routes.checkout.index,
    Component: loadModules("checkout", "Checkout"),
  },
  {
    path: routes.checkout.verification,
    Component: loadModules("checkout", "VerificationCode"),
  },
  {
    path: routes.checkout.success,
    Component: loadModules("checkout", "Success"),
  },
];
