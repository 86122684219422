import React from "react";
import { Route } from "react-router-dom";
import Layout from "../components/Layout";
import SuspsenseFallBack from "../components/SuspsenseFallBack";
import { Iroute } from "../types";

export const generateRoute = ({ path, Component }: Iroute) => {
  return (
    <Route key={path} element={<Layout />}>
      <Route
        path={path}
        element={
          <React.Suspense fallback={<SuspsenseFallBack />}>
            <Component />
          </React.Suspense>
        }
      />
    </Route>
  );
};
