import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { allCountries } from "../../subscriptionservice/store/thunk";
import { BankTransferSlice } from "../types";
import { listAccountTypes } from "./thunk";

const initialState: BankTransferSlice = {
  countryInfo: {
    loading: false,
    data: [],
    selected: {},
  },
  bankSchema: {
    loading: false,
    id: "",
    data: [],
  },
};

const bankTransfer = createSlice({
  name: "Bank-transfer",
  initialState,
  reducers: {
    resetBankSlice: (state) => {
      state.bankSchema.id = initialState.bankSchema.id;
      state.bankSchema.data = initialState.bankSchema.data;
      state.bankSchema.loading = initialState.bankSchema.loading;
      state.countryInfo.loading = initialState.countryInfo.loading;
      state.countryInfo.data = initialState.countryInfo.data;
      state.countryInfo.selected = initialState.countryInfo.selected;
    },
    updateField: (
      state,
      { payload }: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = payload;
      const temp = state.bankSchema.data.map((itm) =>
        itm.name === name ? { ...itm, value } : itm
      );
      // state.bankSchema.id
      state.bankSchema.data = temp;
    },
    selectCountry: (state, { payload }) => {
      state.countryInfo.selected = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(listAccountTypes.fulfilled, (state, { payload }) => {
        const temp = payload[0]?.fields.map((itm: any) => ({
          ...itm,
          value: "",
        }));
        state.bankSchema.id = payload[0]?._id;
        state.bankSchema.data = temp;
      })
      .addCase(allCountries.fulfilled, (state, { payload }) => {
        state.countryInfo.data = payload?.data;
      })
      .addMatcher(isAnyOf(listAccountTypes.pending), (state) => {
        state.bankSchema.loading = true;
      })
      .addMatcher(
        isAnyOf(listAccountTypes.fulfilled, listAccountTypes.rejected),
        (state) => {
          state.bankSchema.loading = false;
        }
      )
      .addMatcher(isAnyOf(allCountries.pending), (state) => {
        state.countryInfo.loading = true;
      })
      .addMatcher(
        isAnyOf(allCountries.fulfilled, allCountries.rejected),
        (state) => {
          state.countryInfo.loading = false;
        }
      );
  },
});

export const bankTransferActions = bankTransfer.actions;

export default bankTransfer.reducer;
