import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import {
  APIResponseModel,
  BankAccountSchema,
  CompleteBankTransfer,
  InitiateBankTransfer,
  ReferenceModel,
  SendOtp,
} from "../types";

const service = {
  async listAccountTypes(
    id: string
  ): Promise<APIResponseModel<BankAccountSchema[]>> {
    const { method, url: makeUrl } = ENDPOINTS.bank_transfer.list_account_type;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async initiateBankTransfer(
    data: InitiateBankTransfer
  ): Promise<APIResponseModel<ReferenceModel>> {
    const { method, url } = ENDPOINTS.bank_transfer.initiate_transfer;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resendOtp(data: SendOtp): Promise<APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.bank_transfer.resend_otp;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async completeBankTransfer(
    data: CompleteBankTransfer
  ): Promise<APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.bank_transfer.complete_transfer;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
