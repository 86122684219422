import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import finswich from "../../../static/images/finswich.svg";
import { errorActions } from "../../../store/errorSlice";

function Layout() {
  const { pathname } = useLocation();
  const merchant_app = useAppSelector(
    (state) => state.AppSlice.user?.merchant_app
  );

  const apply_white_label = merchant_app?.apply_white_label;
  const logo_url = merchant_app?.logo_url;

  const select = pathname.includes("select");

  const errorMessage = useAppSelector((state) => state.error.message);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (errorMessage) {
        dispatch(errorActions.clearMessage());
      }
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [errorMessage]);

  return (
    <main className=" h-screen flex items-center justify-center  bg-blue-70">
      <div
        className={`w-[376px] h-[40rem]  rounded-2xl  relative ${
          // !apply_white_label
          //   ? "bg-no-repeat bg-cover  bg-formbg bg-[50%_95%]"
          //   : ""
          ""
        }  ${select ? "bg-gray-70" : "bg-white"}`}
      >
        {errorMessage && (
          <div className="bg-[#BC0E0E] absolute left-0 right-0 -top-5 rounded-t-2xl px-10 z-50 text-white">
            Hey Chief, {errorMessage}...
          </div>
        )}

        <div className=" h-[34rem] overflow-hidden rounded-t-2xl">
          <Outlet />
        </div>
        <footer
          className={`p-10 select-none ${select ? "bg-gray-70" : "bg-white"}`}
        >
          <span className="inline-block absolute  z-[1] bottom-6 left-1/2 -translate-x-1/2 bg-contain">
            {apply_white_label ? (
              <>
                <span className="text-xs text-gray-60">Powered by</span>
                <img
                  src={logo_url}
                  alt="logo"
                  className="w-12 h-auto  mx-auto object-contain"
                />
              </>
            ) : (
              <img
                src={finswich}
                alt="logo"
                className="w-18 h-auto  mx-auto object-contain "
              />
            )}
          </span>
        </footer>
      </div>
    </main>
  );
}

export default Layout;
