import { combineReducers } from "redux";
import subscriptionservice from "../module/subscriptionservice/store/slice";
import banktransfer from "../module/banktransfer/store/slice";
import AppSlice from "../module/auth/store/slice";
import wallet from "../module/wallet/store/slice";
import utilities from "../module/utilities/store/slice";
import error from "./errorSlice";

const rootReducer = combineReducers({
  subscriptionservice,
  banktransfer,
  AppSlice,
  wallet,
  utilities,
  error,
});

export default rootReducer;
