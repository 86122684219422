import { Middleware } from "redux";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  fetchPublicKey,
  fetchUserRefrence,
} from "../../../common/service/storage";
import { walletActions } from "./slice";
import { Message } from "../types";

const walletMiddleware: Middleware = (store) => {
  let socket: ReconnectingWebSocket;

  return (next) => (action) => {
    const isConnectionEstablished =
      socket && store.getState().wallet.socket.isConnected === true;
    const isConnectionEstablishedNoSocket =
      store.getState().wallet.socket.isConnected === true;
    const amount = action?.payload?.amount;
    const txn_ref = action?.payload?.txn_reference;

    //   establish connection
    if (
      walletActions.startConnecting.type === action?.type &&
      isConnectionEstablishedNoSocket === false &&
      amount
    ) {
      socket = new ReconnectingWebSocket(
        `${process.env.REACT_APP_SOCKET}${
          txn_ref ? `&txn_reference=${txn_ref}` : ""
        }`
      );

      socket.onmessage = ({ data }: MessageEvent<string>) => {
        const res: Message = JSON.parse(data);
        store.dispatch(walletActions.connectionEstablished());
        store.dispatch(walletActions.receiveMessage({ data: res }));

        if (!txn_ref) {
          store.dispatch(
            walletActions.submitMessage({
              content: {
                type: "auth",
                public_key: fetchPublicKey(),
                user_reference: fetchUserRefrence(),
              },
            })
          );
        }
      };
    }

    // recive message when connected
    if (socket?.readyState === 1 && isConnectionEstablished) {
      socket.onmessage = ({ data }: MessageEvent<string>) => {
        const res: Message = JSON.parse(data);
        store.dispatch(walletActions.receiveMessage({ data: res }));
      };
    }

    // send a package back when connected
    if (walletActions.submitMessage.match(action) && isConnectionEstablished) {
      socket?.send(JSON.stringify(action.payload.content));
    }

    if (
      walletActions.startDisconecting.match(action) &&
      isConnectionEstablished
    ) {
      socket.close();
      socket.onclose = (e: any) => {
        store.dispatch(walletActions.disconnectionEstablished());
      };
    }

    next(action);
  };
};

export default walletMiddleware;
