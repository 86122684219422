import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeNonRefrence,
  makeAuthorizedRequestWithHeadersAndPayload,
} from "../../../common/service/request";
import {
  APIResponseModel,
  CurrencySwap,
  IcurrencySwap,
  User,
  UserExist,
  UserMissing,
} from "../types";

const service = {
  async onboardCustomer(data: any): Promise<APIResponseModel<User>> {
    const { method, url } = ENDPOINTS.user.onboard;
    return makeNonRefrence(method, url, data);
  },
  async getCustomer(
    ref: string
  ): Promise<APIResponseModel<UserExist | UserMissing>> {
    const { method, url: MakeUrl } = ENDPOINTS.user.retrieve;
    const url = MakeUrl(ref);

    return makeNonRefrence(method, url);
  },
  async currencySwap(
    data: IcurrencySwap
  ): Promise<APIResponseModel<CurrencySwap>> {
    const { method, url } = ENDPOINTS.payment_util;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
