import { Routes } from "react-router-dom";
import { route } from "./common/routes/route";
import { generateRoute } from "./common/routes/generateRoute";
import { useEffectOnce } from "./common/hooks";
import { clearUserDetails } from "./common/service/storage";

function App() {
  useEffectOnce(() => {
    return () => clearUserDetails();
  });
  return <Routes>{route.map((component) => generateRoute(component))}</Routes>;
}

export default App;
