import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import { GetUser, IcurrencySwap, OnboardUserThunk } from "../types";
import { closeModal } from "./slice";
import { allCountries } from "../../subscriptionservice/store/thunk";
import axios from "axios";

export const onboardCustomer = createAsyncThunk(
  "appState/onboardCustomer",
  async (req: OnboardUserThunk) => {
    const { data, naviagteSuccess } = req;
    return service
      .onboardCustomer(data)
      .then((res) => {
        naviagteSuccess();
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const getCustomer = createAsyncThunk(
  "appState/getCustomer",
  async (data: GetUser, { dispatch, fulfillWithValue }) => {
    return service
      .getCustomer(data.user_reference)
      .then((res) => {
        if (res.data.user_exist === false) {
          data.navigateError();
        }
        dispatch(allCountries());
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        dispatch(closeModal());
        return err;
      });
  }
);
export const convertCurrency = createAsyncThunk(
  "appState/convertCurrency",
  async (data: IcurrencySwap, { fulfillWithValue, rejectWithValue }) => {
    return service
      .currencySwap(data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const getExchangeRate = createAsyncThunk(
  "appState/getExchangeRate",
  async (curr: string, { fulfillWithValue, rejectWithValue }) => {
    return axios
      .get(
        `https://exchanger-api.fuspay.finance/api/v1/no-auth/Exchange/CurrencyExchange?from=usdt&to=${curr}&amount=1`
      )
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
