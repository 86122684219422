import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import * as tp from "../types";

export const fetchCategories = createAsyncThunk(
  "utility/fetchCategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .fetchCategories()
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchSingleCategory = createAsyncThunk(
  "utility/fetchSingleCategory",
  async (req: tp.thunkOps, { rejectWithValue, fulfillWithValue }) => {
    const { category_id } = req;
    return service
      .fetchSingleCategory(category_id)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchOperators = createAsyncThunk(
  "utility/fetchOperators",
  async (req: tp.ThunkOpsProdData, { rejectWithValue, fulfillWithValue }) => {
    const { category_id, data } = req;
    return service
      .fetchOperators(category_id, data)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchOperatorProducts = createAsyncThunk(
  "utility/fetchOperatorProducts",
  async (req: tp.thunkOpsProd, { rejectWithValue, fulfillWithValue }) => {
    const { category_id, operators_id } = req;
    return service
      .fetchOperatorProducts(category_id, operators_id)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchSingleOperatorProduct = createAsyncThunk(
  "utility/fetchSingleOperatorProduct",
  async (req: tp.thunkSopsProd, { rejectWithValue, fulfillWithValue }) => {
    const { category_id, operators_id, product_id } = req;
    return service
      .fetchSingleOperatorProduct(category_id, operators_id, product_id)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const validatePhoneNumber = createAsyncThunk(
  "utility/validatePhoneNumber",
  async (req: tp.thunkValidatePhone, { rejectWithValue, fulfillWithValue }) => {
    return service
      .validatePhoneNumber(req)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const initiateTransaction = createAsyncThunk(
  "utility/initiateTransaction",
  async (
    req: tp.thunkInitiateTransaction,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .initiateTransaction(req)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const resendOtp = createAsyncThunk(
  "utility/resendOtp",
  async (req: tp.thunkResendOtp, { rejectWithValue, fulfillWithValue }) => {
    return service
      .resendOtp(req)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const completeTransaction = createAsyncThunk(
  "utility/completeTransaction",
  async (
    req: tp.thunkCompleteTransaction,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .completeTransaction(req)
      .then((res) => {
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
