import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import { IcreateService, IupdateService, QueryListParams } from "../types";

export const allCountries = createAsyncThunk(
  "subscriptionService/country-list",
  async () => {
    return service
      .allCountries()
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const listSubscriptionService = createAsyncThunk(
  "subscriptionService/list",
  async () => {
    return service
      .listUserSubscriptionService()
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const createSubscriptionService = createAsyncThunk(
  "subscriptionService/create",
  async (data: IcreateService) => {
    return service
      .createUserSubscriptionService(data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const updateSubscriptionService = createAsyncThunk(
  "subscriptionService/update",
  async (req: IupdateService) => {
    return service
      .updateUserSubscriptionService(req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const singleSubscriptionService = createAsyncThunk(
  "subscriptionService/specific-service",
  async (id: string) => {
    return service
      .singleSubscriptionService(id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const listAllSubscriptionService = createAsyncThunk(
  "subscriptionService/list-all",
  async (params: QueryListParams) => {
    return service
      .listAllSubscriptionService(params)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);

export const singleAppSubscriptionService = createAsyncThunk(
  "subscriptionService/singleAppSubscriptionService",
  async (id: string) => {
    return service
      .singleAppSubscriptionService(id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
