import { STORAGE_KEYS } from "../config/constant";

export const getItem = (key: string) => {
  return localStorage.getItem(key) || null;
};
export const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const setItem = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const clearData = () => localStorage.clear();

export const clearUserDetails = () => {
  removeItem(STORAGE_KEYS.CLIENT_PUBLIC_KEY);
  removeItem(STORAGE_KEYS.CLIENT_USER_REFRENCE);
  removeItem(STORAGE_KEYS.CLIENT_ORIGIN);
};

export const storePublicKey = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_PUBLIC_KEY, token);
};
export const storeOrigin = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_ORIGIN, token);
};
export const storeUserRefrence = (token: string) => {
  return setItem(STORAGE_KEYS.CLIENT_USER_REFRENCE, token);
};

export const fetchPublicKey = () => {
  return getItem(STORAGE_KEYS.CLIENT_PUBLIC_KEY);
};
export const fetchOrigin = () => {
  return getItem(STORAGE_KEYS.CLIENT_ORIGIN);
};
export const fetchUserRefrence = () => {
  return getItem(STORAGE_KEYS.CLIENT_USER_REFRENCE);
};
