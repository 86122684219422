import React from "react";
import Loader from "../Loader";

function SuspsenseFallBack() {
  return (
    <div
      role="status"
      className="flex  items-center justify-center h-[34rem] bg-transparent"
    >
      <Loader />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default SuspsenseFallBack;
