import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import {
  IcompleteTransfer,
  IresendOtp,
  QueryListParams,
  IresolveCustomerAccount,
  IinitiateTransferThunk,
  IuploadEvidenceThunk,
} from "../types";

export const listPlatforms = createAsyncThunk(
  "wallet-transfer/listPlatforms",
  async (query: QueryListParams) => {
    return service
      .listPlatforms(query)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
);
export const initiateTransfer = createAsyncThunk(
  "wallet-transfer/initiateTransfer",
  async (
    req: IinitiateTransferThunk,
    { fulfillWithValue, rejectWithValue }
  ) => {
    const { data, errorCallBack, navigateSuccess } = req;
    return service
      .initiateTransfer(data)
      .then((res) => {
        navigateSuccess(res.data);
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        errorCallBack(err?.message);
        return rejectWithValue(err);
      });
  }
);
export const resendOtp = createAsyncThunk(
  "wallet-transfer/resendOtp",
  async (req: IresendOtp, { fulfillWithValue, rejectWithValue }) => {
    return service
      .resendOtp(req)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const completeTransfer = createAsyncThunk(
  "wallet-transfer/completeTransfer",
  async (req: IcompleteTransfer, { fulfillWithValue, rejectWithValue }) => {
    return service
      .completeTransfer(req)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const resolveCustomerAccountWithPhoneNumber = createAsyncThunk(
  "wallet-transfer/resolveCustomerAccountWithPhoneNumber",
  async (
    req: IresolveCustomerAccount,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service
      .resolveCustomerAccountWithPhoneNumber(req)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const uploadPaymentEvidence = createAsyncThunk(
  "wallet/uploadPaymentEvidence",
  async (req: IuploadEvidenceThunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadPaymentEvidence(req.data)
      .then((res) => {
        req.navigateSuccess();
        return fulfillWithValue(res?.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
