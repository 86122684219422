import axios, { AxiosResponse } from "axios";
import store from "../../../store";
import { closeModal } from "../../../module/auth/store/slice";
import { APIResponseSuccessModel } from "../../types";
import { BASE_URL as API_URL, REQUEST_TIMEOUT } from "../config/constant";

import {
  /* clearData, */ fetchOrigin,
  fetchPublicKey,
  fetchUserRefrence,
} from "../storage";

/** general headers **/
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/** authorization header for logged in user **/
const setAuthorization = () => ({
  "x-public-key": fetchPublicKey(),
  "x-user-reference": fetchUserRefrence(),
  "x-origin": fetchOrigin(),
});
const setAuthorizationNonRef = () => ({
  "x-public-key": fetchPublicKey(),
  "x-origin": fetchOrigin(),
});

/** axios instance **/
const instance = axios.create({
  baseURL: API_URL,
  headers,
  withCredentials: false,
});

/** timeout configuration for axios instance **/
instance.defaults.timeout = REQUEST_TIMEOUT;

/** axios interceptor to trigger a logout on unauthorized error response code **/
instance.interceptors.response.use(
  ({ data }: AxiosResponse): AxiosResponse<APIResponseSuccessModel> => {
    return data;
  },
  (error: any) => {
    if (error.response?.status === 401) {
      store.dispatch(closeModal());
      return Promise.reject({
        status: 401,
        message: "Login session expired, please login again",
      });
    }

    return Promise.reject(
      error ? (error.response ? error.response.data : error.response) : null
      // : Language.NetworkErrorMessage.errorMessage
    );
  }
);

/** make an axios get request **/
export const makeGetRequest = (path: string) => instance.get(path);

/** make an axios post request **/
export const makePostRequest = (path: string, payload: any) =>
  instance.post(path, payload);

/** make an axios request for a guest **/
export const makeUnauthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url,
    data,
    headers,
  });
  return response;
};

/** make an axios request for logged-in user **/
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};
export const makeNonRefrence = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorizationNonRef(),
    },
  });
  return response;
};
export const makeAuthorizedRequestDownload = async (
  method: string,
  url: string,
  data = {}
) => {
  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
    responseType: "arraybuffer",
  });
  return response;
};

/** make an axios request to submit a file for a logged in user **/
export const makeRequestWithFormData = async (
  method: string,
  url: string,
  data: any,
  authorized: any
) => {
  /** create new formdata object **/
  let formData = new FormData();

  let headers = {
    "Content-Type": "multipart/form-data",
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      let fieldData = data[key];
      // if (key === "texts" && data[key] !== "") {
      //   data[key].forEach((txtfield, idx) => {
      //     formData.append(`texts[${idx}][format]`, txtfield.format);
      //     formData.append(`texts[${idx}][text]`, txtfield.text);
      //   });
      // }
      // if (key === "media" && data[key].length !== 0) {
      //   data[key].forEach((image) => {
      //     formData.append(key, image.src);
      //   });
      // }

      if (key !== "channels") {
        formData.append(key, fieldData);
      }
      if (key === "channels") {
        formData.append(key, JSON.stringify(fieldData));
      }
    }
  }

  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  const response: APIResponseSuccessModel = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
  // return Promise.resolve({ messsage: null });
};
