import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeRequestWithFormData,
} from "../../../common/service/request";
import {
  APIResponseModel,
  IcompleteTransfer,
  IinitiateTransfer,
  IresendOtp,
  listModel,
  QueryListParams,
  referenceModel,
  resolvedModel,
  IresolveCustomerAccount,
  IuploadEvidence,
} from "../types";

const service = {
  async listPlatforms(
    query: QueryListParams
  ): Promise<APIResponseModel<listModel[]>> {
    const { method, url: makeUrl } = ENDPOINTS.wallet_transfer.list_platforms;
    const url = makeUrl(query);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async initiateTransfer(
    data: IinitiateTransfer
  ): Promise<APIResponseModel<referenceModel>> {
    const { method, url } = ENDPOINTS.wallet_transfer.initiate_transfer;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resendOtp(data: IresendOtp): Promise<APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.wallet_transfer.resend_otp;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async completeTransfer(
    data: IcompleteTransfer
  ): Promise<APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.wallet_transfer.complete_transfer;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async resolveCustomerAccountWithPhoneNumber(
    data: IresolveCustomerAccount
  ): Promise<APIResponseModel<resolvedModel>> {
    const { method, url } =
      ENDPOINTS.wallet_transfer.reslove_customer_account_with_phone_number;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async uploadPaymentEvidence(
    data: IuploadEvidence
  ): Promise<APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.fund_wallet.upload_evidence;
    return makeRequestWithFormData(method, url, data, true);
  },
};

export default service;
